import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Push Press 5×2\\@90% 1RM`}</p>
    <p>{`Dumbbell Pullovers 5×4`}</p>
    <p>{`then,`}</p>
    <p>{`20-Push Press (115/75)(RX+ 135/95)`}</p>
    <p>{`20-Lateral Burpees Over Bar`}</p>
    <p>{`20-Push Jerks`}</p>
    <p>{`20-Lateral Burpees Over Bar`}</p>
    <p>{`20-Split Jerks`}</p>
    <p>{`20-Lateral Burpees Over Bar`}</p>
    <p>{`for time.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`Sign up for the Support Your Local Box at home competition today
at: `}</em></strong><a parentName="p" {...{
        "href": "https://games.crossfit.com"
      }}><strong parentName="a"><em parentName="strong">{`https://games.crossfit.com`}</em></strong></a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      